import { Routes, UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';
import { localeRedirect } from './guards/locale-redirect.guard';
import { isDevMode } from '@angular/core';
import { CheckoutGuard } from './guards/checkout.guard';

export type RouteKey =
  | 'cart'
  | 'stores'
  | 'checkout'
  | 'staticPages'
  | 'search'
  | 'terms'
  | 'privacyPolicy';

export const ROUTE_TRANSLATIONS: Record<string, Record<RouteKey, string> | undefined> = {
  en: {
    cart: 'cart',
    stores: 'stores',
    checkout: 'checkout',
    staticPages: 'pages',
    search: 'search',
    terms: 'pages/terms-and-conditions',
    privacyPolicy: 'pages/privacy-policy',
  },
  cs: {
    cart: 'kosík',
    stores: 'obchody',
    checkout: 'pokladna',
    staticPages: 'stranky',
    search: 'vyhledavani',
    terms: 'stranky/obchodni-podminky',
    privacyPolicy: 'stranky/zasady-ochrany-osobnich-udaju',
  },
  sk: {
    cart: 'kosík',
    stores: 'obchodoch',
    checkout: 'pokladna',
    staticPages: 'stranky',
    search: 'vyhladavanie',
    terms: 'stranky/obchodne-podmienky',
    privacyPolicy: 'stranky/politika-ochrany-osobnych-udajov-suborov-cookies-sk',
  },
};

const langMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  return segments.length === 0 || segments[0].path.length !== 2
    ? { consumed: [] }
    : { consumed: segments.slice(0, 1) };
};

const isTaxonPath = (segments: UrlSegment[]): UrlMatchResult | null => {
  return segments.length > 0 && segments[0].path === 't' ? { consumed: segments } : null;
};

const isTranslatedUrl = (routeKey: RouteKey): UrlMatcher => {
  return (segments, { segments: [{ path: locale }] }) => {
    return segments.length > 0 &&
      segments[0].path === ROUTE_TRANSLATIONS[locale]?.[routeKey]
      ? { consumed: segments.slice(0, 1) }
      : null;
  };
};

const baseRoutes: Routes = [
  {
    matcher: langMatcher,
    canActivate: [localeRedirect],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },

      {
        matcher: isTranslatedUrl('cart'),
        loadComponent: () =>
          import('./pages/cart-page/cart-page.component').then(
            (m) => m.CartPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('search'),
        loadComponent: () =>
          import('./pages/product/product-list/product-list.component').then(
            (m) => m.ProductListComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('stores'),
        loadComponent: () =>
          import('./pages/stores/stores.component').then((m) => m.StoresComponent),
      },
      {
        path: 'p/:productUrl',
        loadComponent: () =>
          import('./pages/product/product-page/product-page.component').then(
            (m) => m.ProductPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('checkout'),
        canActivate: [CheckoutGuard],
        runGuardsAndResolvers: 'always',
        loadComponent: () =>
          import('./pages/checkout-page/checkout-page.component').then(
            (m) => m.CheckoutPageComponent,
          ),
      },
      {
        matcher: isTranslatedUrl('staticPages'),
        loadChildren: () =>
          import('./pages/static/static.routes').then((m) => m.staticRoutes),
      },
      {
        matcher: isTaxonPath,
        loadComponent: () =>
          import('./pages/product/product-list/product-list.component').then(
            (m) => m.ProductListComponent,
          ),
      },
      {
        path: 'order-status',
        loadComponent: () =>
          import('./pages/thank-you/thank-you.component').then(
            (m) => m.ThankYouComponent,
          ),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];

if (isDevMode()) {
  baseRoutes.unshift({
    path: 'design-system',
    loadChildren: () =>
      import('./pages/design-system/design-system.routes').then((m) => m.routes),
  });
}

export const routes = baseRoutes;
